.sign-up-ctn {
  // background: url('../../assets/images/common/abc.png');
  background-color: var(--ion-background);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: scroll;

  .card-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiSvgIcon-root {
      color: #fff;
      width: 2rem;
      margin-top: 10px;
      cursor: pointer;
      &.no-margin {
        margin-top: 2px;
        color: var(--ion-color-dark);
      }  
    }
    .title-row {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      align-items: center;
      .logo {
        max-height: var(--logo-xl-height);
        margin-bottom: 10px;
      }
    }

    .card-bg {
      background-color: var(--signup-card-background-color);
      // linear-gradient(var(--ion-nav-card-text), var(--ion-color-primary));
      position: relative;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      margin-top: 20px;
      .card-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 405px;
        width: 710px;
        left: 365px;
        top: 163px;
        border-radius: 10px;
        z-index: 6;
        .card {
          display: flex;
          flex-direction: column;
          padding-top: 20px;
          .stepper {
            padding: 6px;
          }
          .page-title {
            font-size: 26px;
            color: var(--ion-color-primary);
            text-align: center;
            padding-top: 5px;
          }
          .react-tel-input {
            height: 48px;
            width: 300px;
            margin-left: 10px;
            background-color: #EAEEF3;
border: 1px solid #EAEEF3;
            border-radius: 6px;
            font-size: 12px;
            color: var(--input-text-color) !important;
          }
          .react-tel-input .form-control {
            background-color: var(--ion-color-light);
            border: 1px solid var(--ion-color-light);
            border-radius: 6px;
            font-size: 12px;
            color: var(--input-text-color) !important;
            height: 48px;
          }
          .react-tel-input .country-list .country-name {
            color: var(--ion-color-dark);
          }
          .react-tel-input .flag-dropdown {
            background-color: var(--ion-color-light);
            border: 1px solid var(--ion-color-light);
            border-radius: 3px;
            font-size: 12px;
            height: 48px;
          }
          .number-input {
            margin-top: 0px;
          }
          .MuiFormGroup-root {
            margin-top: 10px;
            &.mt-0 {
              margin-top: 0px;
            }
            .MuiTypography-body1 {
              font-size: 12px;
              margin-top: 12px;
              color: var(--signup-font-color);
            }
          }
          .info {
            font-size: 12px;
            // padding: 15px;
            color: #fff;
            opacity: 0.7;
            margin-top: 15px;
          }
          .btn-section {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            .btn {
              width: 210px;
              background-color: var(--ion-color-primary);
              &:not(.MuiButton-contained.Mui-disabled) {
                font-weight: bold;
                color: var(--ion-color-dark);
                background-color: var(--ion-color-primary);  
              }
            }
          }
          .msg-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            .msg {
              margin-top: 4px;
              text-align: center;
              color: var(--signup-font-color);
              font-size: 13px;
              opacity: 0.7;
              cursor: pointer;
            }
          }
          .login-btn {
            margin-top: 20px;
          }

          .input-row {
            margin-top: 20px;
            // justify-content: space-around;
            &.flex-column {
              display: flex;
              flex-direction: column;
            }
            .label {
              color: var(--signup-font-color);
              font-size: 14px;
              margin-bottom: 10px;
              margin-left: 15px;
              &.link {
                font-size: 10px;
                text-decoration: underline;
                color: var(--ion-color-primary);
                float: right;
                margin-left: auto;
                cursor: pointer;
              }
              &.verify-text {
                margin-top: -30px;
                margin-left: 100px;
                font-size: x-large;
                font-weight: bold;
              }
            }
            .MuiFormControl-root {
              width: 300px;
              margin-left: 10px;
              // background-color: var(--ion-color-dark);
              // border: 1px solid var(--ion-color-dark);
              // border-radius: 3px;
              font-size: 12px;
              color: #fff !important;
              .MuiFormHelperText-contained {
                color: crimson;
              }
            }
            .MuiInputBase-root {
              font-size: 12px !important;
              color: var(--input-text-color) !important;
              border: 0px;
            }
            .MuiInputBase-root .MuiInputBase-input,
            .MuiInputBase-root .MuiOutlinedInput-input {
              padding: 16px 16px;
              font-size: 12px !important;
              background-color: #EAEEF3;
border: 1px solid #EAEEF3;
              // border: 1px solid var(--ion-color-dark);
            }
            .MuiOutlinedInput-adornedEnd {
              background-color: var(--ion-color-light) !important;
            }
          }
          .button-row {
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .back-form-btn {
              width: 120px;
              margin-bottom: 20px;
              margin-right: 10px;
              background-color: var(--ion-background);
            }
            .signup-btn {
              width: 120px;
              margin-bottom: 20px;
              font-weight: bold;
              color: var(--ion-color-light);
              &:not(.MuiButton-contained.Mui-disabled) {
                font-weight: bold;
                color: var(--ion-color-dark);
                background-color: var(--ion-color-primary);  
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 721px) and (max-width: 1200px) {
  .sign-up-ctn {
    .card-col {
      .card-bg {
        .card-section {
          width: 650px;
        }
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .sign-up-ctn {
    // background: url('../../assets/images/common/abcmob.png') !important;
    background-color: var(--ion-background) !important;
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .card-col {
      display: block;
      justify-content: center;
      align-items: center;
      .title-row {
        // position: absolute;
        // top: 20%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }
      .card-bg {
        // top: 43%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        background-size: cover;
        display: block;
        margin: 0 11px;
        .card-section {
          min-height: 400px;
          width: 100%;
          border-radius: 10px;
          z-index: 6;
          .card {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            .page-title {
              font-size: 18px;
              padding-top: 10px;
            }
            .input-col {
              display: flex;
              justify-content: center;
            }
            .input-row {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-top: 20px;
              .label {
                color: var(--signup-font-color);
                font-size: 12px;
                margin-bottom: 10px;
                margin-left: 15px;
              }
            }
            .btn-section {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
              .btn {
                width: 210px;
                font-weight: bold;
              }
            }

            .button-row {
              margin-top: 40px;
              display: flex;
              flex-direction: row;
              justify-content: center;

              .back-form-btn {
                width: 120px;
                margin-bottom: 20px;
                margin-right: 10px;
                background-color: var(--ion-background);
              }
              .signup-btn {
                width: 120px;
                margin-bottom: 20px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
.sign-up-ctn .card-col {
  .MuiPaper-root {
    background: none !important;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: var(--signup-font-color);
  }
  .MuiSvgIcon-root {
    color: var(--ion-color-primary);
  }
}
.MuiPaper-root {
  background: none !important;
}

