.fgt-pwd {
  background-color: var(--ion-background);
  height: 100vh;


  .title-row {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;

    .logo {
      max-height: var(--logo-xl-height);
      margin-bottom: 10px;
    }
  }

  .form-ctn {
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      color: var(--signup-font-color);
    }

    .MuiStepConnector-alternativeLabel {
      top: 12px;
    }

    .MuiSvgIcon-root {
      color: var(--ion-color-primary);
    }

    background-color: #fff;
    width: 710px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    border-radius: 10px;

    .page-title {
      font-size: 26px;
      color: var(--ion-color-primary);
      text-align: center;
      padding-top: 5px;
    }

    .forgot-pwd-form-ctn {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .usr-input,
      .code-input,
      .pwd-input {
        display: inline-grid;

        .MuiFormControl-root {
          margin: 0
        }
      }

      .confirm-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        & {
          background-color: var(--ion-color-primary);

          &:not(.MuiButton-contained.Mui-disabled) {
            font-weight: bold;
            color: var(--ion-color-dark);
            background-color: var(--ion-color-primary);
          }
        }
      }

      .MuiFormControl-root {
        width: 300px;
        margin-left: 10px;
        font-size: 12px;
        color: #fff !important;

        .MuiFormHelperText-contained {
          color: crimson;
        }

        .MuiSvgIcon-root {
          color: var(--ion-background);
        }

        @media (max-width: 350px) {
          width: 200px;
        }
      }

      .MuiInputBase-root {
        font-size: 12px !important;
        color: var(--input-text-color) !important;
        border: 0px;
      }

      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        padding: 16px 16px;
        font-size: 12px !important;
        background-color: #EAEEF3;
        border: 1px solid #EAEEF3;
      }

      .MuiOutlinedInput-adornedEnd {
        background-color: var(--ion-color-light) !important;
      }


      .error-msg {
        color: crimson;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.66;
        text-align: center;
      }

      .success-msg {
        color: var(--ion-color-success);
        margin-top: 20px;
        text-align: center;
      }

      .usr-input,
      .pwd-input,
      .code-input {
        margin-top: 12px;
      }

      .required {
        color: red;
      }

      .user-name,
      .pwd-field {
        border-radius: 10px;
        height: 48px;
        background-color: #EAEEF3 !important;
      }

      .msg-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .msg {
          margin-top: 4px;
          text-align: center;
          color: var(--signup-font-color);
          font-size: 13px;
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }

    .reset-sucess {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;

      .center {
        margin: auto;
      }

      .tick-success {
        width: 100px;
        height: 100px;
      }

      .message {
        margin: 1rem;
        font-weight: 500;
        text-align: center;
        color: var(--ion-color-primary-contrast);
      }
    }
  }


  @media (max-width: 720px) {
    .form-ctn {
      width: 90vw;
      min-height: 65vh;
    }

    .user-name,
    .pwd-field,
    .login-form-btn {
      max-width: 100% !important;
    }
  }
}

.option-selection{
  height: 45px;
  margin-bottom: 15px;
}