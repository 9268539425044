.login-ctn {
  // background: url('../../assets/images/common/abc.png');
  background-color: var(--ion-background);
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .title-row {
    top: 15%;
    left: 50%;
    text-align: center;
    padding-top: 20px;
    .logo {
      max-width: var(--login-logo-xl-width);
      min-height: var(--login-logo-xl-height);
      margin-bottom: 10px;
    }
  }

  .login-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-53%, -50%);
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .login-ctn {
    // background: url('../../assets/images/common/abc.png');
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    .bottom-line {
      padding-top: 8px;
    }
    .title-row {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login-card {
      position: absolute;
      top: 54%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 600px) {
  .login-ctn {
    // background: url('../../assets/images/common/abcmob.png') !important;
    // background-color: var(--ion-background) !important;
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    .title-row {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media only screen and (device-width: 360px) {
  .login-ctn {
    // background: url('../../assets/images/common/abcsmall.png');
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    .title-row {
      position: absolute;
      top: 16%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login-card {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
