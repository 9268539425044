.thank-you-ctn {
    background: url('../../assets/images/b2cbanner/thank-you-wallpaper-ipl.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    padding: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 920px) {
    background: url('../../assets/images/b2cbanner/thank-you-wallpaper.png') !important;
    }

    .w-90 {
        width: 89%;
        cursor: pointer;
        @media screen and (max-width: 720px) {
            width: 86%;
        }
    }

    .pb-14 {
        padding-bottom: 14px;
    }

    .welcome-card {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Montserrat', sans-serif !important;

        .wel-item-ctn {
            margin-top: 1.2rem;
            // width: 380px;
            min-width: 380px;
            width: 488px;
            max-height: unset;
            display: flex;
            padding: 0%;
            flex-direction: column;
            background-color: #1e1e1e;
            border-radius: 17px;
            overflow: hidden !important;

            @media screen and (max-width: 720px) {
                width: 368px;
                min-width: unset;
            }
            .content_align_cen {
                text-align: center;
            }

            .text-green {
                color: #08BA62;
            }
            .welcome_logo {
                width: 173px;
                margin: 28px auto 0px;
                ;
            }

            .text1_align {
                font-size: 14px;
                color: #ffffff;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif !important;
            }

            .service_align {
                font-size: 14px;
                color: #545454;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif !important;
                display: flex;
                align-content: center;
                margin-bottom: 10px;
            }

            .text_align_WelBonus {
                font-size: 24px;
                font-weight: 600;
                padding: 10px 0;
                font-family: 'Montserrat', sans-serif !important;
            }

            .text_algin_2 {
                font-family: Bebas Neue !important;
                font-size: 80px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: 0.03em;
                text-align: center;
                color: #08BA62;
                display: block;
            }

            .cashback_align {
                font-family: Montserrat !important;
                font-size: 19px;
                font-weight: 700;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: center;
                color: #08BA62;
            }

            .payment-icons-block {
                width: 90%;
                margin: 0 auto;

                img {
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

            }

            .icon_align {
                width: 23px;
                margin-right: 6px;
            }

            .claim-btn-wel {
                width: 228px;
                font-family: Jost;
            }

            .claim-btn-fair {
                width: 150px;
                font-family: Jost;
            }

            .icon_bank_align {
                width: 34px;

            }

            .icon_paytm_align {
                width: 55px;

            }

            .icon_upi_align {
                width: 59px;

            }

            .icon_phonepe_align {
                width: 36px;

            }

            .icon_googleplay_align {
                width: 48px;

            }

            .mrl25 {
                margin-left: 25px;
            }

            .payment-method {
                padding-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 390px) {

    .welcome-ctn {
        .welcome-card {
            position: initial;
            transform: none;
            margin: 0 20px;

            .wel-item-ctn {
                width: 100%;
                min-width: 100%;
                margin-top: 1rem;

                .welcome_logo {
                    width: 150px;
                    margin: 15px auto 0px
                }

                .text_align_WelBonus {
                    font-size: 20px;
                    padding: 0px 0;
                }

                .mrl25 {
                    margin-left: 10px;
                }

                .text_algin_2 {
                    font-size: 60px;
                }

                .icon_bank_align {
                    width: 26px;
                }

                .payment-icons-block img {
                    margin-right: 15px;
                }

                .icon_upi_align {
                    width: 46px;
                }

                .icon_paytm_align {
                    width: 46px;
                }

                .icon_phonepe_align {
                    width: 32px;
                }

                .icon_googleplay_align {
                    width: 42px;
                }

                .service_align {
                    font-size: 13px;
                }

                .cashback_align {
                    font-size: 16px;
                    line-height: 0;
                }
            }
        }
    }

}